import Typed from 'typed.js';

var options = {
    //strings: ['izradu web stranica', 'izradu web trgovina', 'razvoj web aplikacija', 'izradu web stranica' ],
    stringsElement: '#typed-strings',
    typeSpeed: 60,
    startDelay: 8000,
    backDelay: 2000,
};

var typed = new Typed('.home-text .title b', options);
